import React from 'react';
import Button from '@paprika/button';
import Modal from '@paprika/modal';
import FormElement from '@paprika/form-element';
import Input from '@paprika/input';
import PinCode from 'react-verification-code-input';

import './CreateSessionModal.scss';
import { NewSharingSession } from '../../types';

interface CreateSessionModalProps {
  isOpen: boolean;
  onClose: () => void;
  isSaving: boolean;
  createSharingSession: (newSession: NewSharingSession) => Promise<void>;
}

interface ReactElement {
  children: JSX.Element | string | any;
}

interface FormErrors {
  name?: string;
  description?: string;
  adminPin?: string;
}

// Essentially a type declaration for the Header child components.
const Header: (props: ReactElement) => JSX.Element = (Modal as any).Header;
const Content: (props: ReactElement) => JSX.Element = (Modal as any).Content;
const Footer: (props: ReactElement) => JSX.Element = (Modal as any).Footer;

export default function CreateSessionModal({
  isOpen,
  onClose,
  isSaving,
  createSharingSession,
}: CreateSessionModalProps): JSX.Element {
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [adminPin, setAdminPin] = React.useState('');

  const [errors, setErrors] = React.useState<FormErrors | null>(null);

  const check = {
    hasNameError: !!(errors && errors.name),
    hasDescriptionError: !!(errors && errors.description),
    hasPinError: !!(errors && errors.adminPin),
  };

  const getFormErrors = (): FormErrors | null => {
    setErrors(null);
    const errors: FormErrors = {};

    if (name.length === 0) {
      errors.name = 'A session name must be entered.';
    }

    if (description.length === 0) {
      errors.description = 'A session description must be entered.';
    }

    if (adminPin.length < 6) {
      errors.adminPin = 'A 6 digit administration pin code must be entered.';
    }

    return Object.entries(errors).length > 0 ? errors : null;
  };

  const handleSave = async () => {
    const formErrors = getFormErrors();

    if (formErrors !== null) {
      setErrors(formErrors);
      return;
    }

    await createSharingSession({ name, description, adminPin });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Header>Create a New Sharing Session</Header>
      <Content>
        <div className="session-modal-form-element">
          <FormElement>
            <FormElement.Label>Session Name</FormElement.Label>
            <FormElement.Content>
              <Input value={name} onChange={({ target: { value } }) => setName(value)} isReadOnly={isSaving} />
            </FormElement.Content>
            {check.hasNameError && <FormElement.Error>{errors?.name}</FormElement.Error>}
          </FormElement>
        </div>
        <div className="session-modal-form-element">
          <FormElement>
            <FormElement.Label>Description</FormElement.Label>
            <FormElement.Content>
              <Input
                value={description}
                onChange={({ target: { value } }) => setDescription(value)}
                isReadOnly={isSaving}
              />
            </FormElement.Content>
            {check.hasDescriptionError && <FormElement.Error>{errors?.description}</FormElement.Error>}
          </FormElement>
        </div>
        <div className="session-modal-form-element">
          <FormElement>
            <FormElement.Label>Admin Pin</FormElement.Label>
            <FormElement.Instructions>
              A six digit pin code used to access administrative privileges to your session, including updating or
              deleting the scenario of the session.
            </FormElement.Instructions>
            <FormElement.Content>
              <PinCode
                type="number"
                autoFocus={false}
                fields={6}
                loading={isSaving}
                onChange={(newPin) => setAdminPin(newPin)}
              />
            </FormElement.Content>
            {check.hasPinError && <FormElement.Error>{errors?.adminPin}</FormElement.Error>}
            <FormElement.Description>
              <b>Note:</b> This pin is NOT encypted, so do not reuse sensitive values such as banking pins or phone
              unlock codes.
            </FormElement.Description>
          </FormElement>
        </div>
      </Content>
      <Footer>
        <Button onClick={handleSave} kind="primary" isPending={isSaving}>
          Save
        </Button>
        <Button onClick={onClose} kind="minor" isDisabled={isSaving}>
          Cancel
        </Button>
      </Footer>
    </Modal>
  );
}
