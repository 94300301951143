import React from 'react';
import { useFetch } from './useFetch';
import { createSharingSession, getSharingSessions } from '../api/sharingSessions';
import { NewSharingSession, SharingSession } from '../types';

interface UseSharingSessionResult {
  sharingSessions: SharingSession[];
  isLoading: boolean;
  isCreatingSession: boolean;
  isGettingSessions: boolean;
  hasErrors: boolean;
  getError: Error | null;
  createError: Error | null;
  createNewSharingSession: (newSession: NewSharingSession) => Promise<SharingSession>;
}

export function useSharingSessions(): UseSharingSessionResult {
  const { isLoading: isCreatingSession, load: createSession, error: createError } = useFetch(createSharingSession);
  const { data: sharingSessions, isLoading: isGettingSessions, load: getSessions, error } = useFetch(
    getSharingSessions,
    { loadOnMount: true },
  );

  const createNewSharingSession = React.useCallback(
    async ({ name, description, adminPin }: NewSharingSession): Promise<SharingSession> => {
      const session = await createSession({ name, description, adminPin });

      // Refreshes the sharing sessions
      // Intensionally do not await since caller may want to run functionality immedidately after create.
      getSessions();

      return session;
    },
    [createSession, getSessions],
  );

  return {
    sharingSessions,
    isLoading: isGettingSessions || isCreatingSession,
    isGettingSessions,
    isCreatingSession,
    hasErrors: !!error || !!createError,
    getError: error,
    createError,
    createNewSharingSession,
  };
}
