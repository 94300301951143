import React from 'react';

import Tabs from '@paprika/tabs';

import { Notification, Scenario } from '../../types';
import NotificationBubbly from '../NotificationBubbly/NotificationBubbly';
import CodeEditor from '../CodeEditor/CodeEditor';

interface SectionListProps {
  notify: (notification: Notification) => void;
  scenarios: Scenario[];
}

export function ScenarioList({ scenarios, notify }: SectionListProps): JSX.Element {
  if (scenarios.length === 0) {
    return (
      <NotificationBubbly
        headerText={'No Scenarios to Share!'}
        bodyText={`This sharing session has no scenarios! If you are the admin, edit this sharing session in the admin console to add some content.`}
      />
    );
  }

  return (
    <Tabs isVertical={false} hasInsetFocusStyle>
      <div className="section-list-tabs">
        <Tabs.List>
          {scenarios.map((_, index) => (
            <Tabs.Tab key={`section_${index}`} isSelected={false}>
              #{index + 1}
            </Tabs.Tab>
          ))}
        </Tabs.List>
      </div>
      <div className="section-list-content">
        <Tabs.Panels>
          {scenarios.map((scenario, index) => (
            <Tabs.Panel key={`section_${index}`}>
              <h3>{scenario.name}</h3>
              <p style={{ whiteSpace: 'pre-line' }}>{scenario.description}</p>
              <CodeEditor startingCode={scenario.startingCode} notify={notify} />
            </Tabs.Panel>
          ))}
        </Tabs.Panels>
      </div>
    </Tabs>
  );
}
