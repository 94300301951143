import React from 'react';
import L10n from '@paprika/l10n';
import { Notification } from './types';
import NotificationToast from './components/NotificationToast/NotificationToast';
import ListSessionPage from './pages/ListSessionPage/ListSessionPage';
import SharingSessionPage from './pages/SharingSessionPage/SharingSessionPage';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import './App.scss';

function App(): JSX.Element {
  const [notification, setNotification] = React.useState<Notification | null>(null);

  const notify = React.useCallback(
    ({ title, message, type, link }: Notification) =>
      setNotification({
        title,
        message,
        type,
        link,
      }),
    [],
  );

  return (
    <div className="app">
      <L10n locale="en">
        <Router>
          {!!notification && <NotificationToast {...notification} onClose={() => setNotification(null)} />}
          <Switch>
            <Route path="/sessions/:id">
              <SharingSessionPage notify={notify} />
            </Route>
            <Route path="/sessions">
              <ListSessionPage notify={notify} />
            </Route>
            <Route path="/">
              <Redirect to="/sessions" />
            </Route>
          </Switch>
        </Router>
      </L10n>
    </div>
  );
}

export default App;
