import React from 'react';
import './Card.scss';

export interface CardProps {
  children?: JSX.Element | string;
}

function Card({ children }: CardProps): JSX.Element {
  return <div className="card">{children}</div>;
}

export default Card;
