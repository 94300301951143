export type Json = any;

export default class Http {
  static get<T>(url: string): Promise<T> {
    return this.makeRequest(url);
  }

  static post<T>(url: string, body: Json): Promise<T> {
    return this.makeRequest(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
  }

  static put<T>(url: string, body: Json): Promise<T> {
    return this.makeRequest(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
  }

  static delete<T>(url: string): Promise<T> {
    return this.makeRequest(url, {
      method: 'DELETE',
    });
  }

  private static async makeRequest<T>(url: string, options = {}): Promise<T> {
    const response = await fetch(url, options);
    const json = await response.json().then(
      (data) => data,
      (error) => error,
    );

    if (!response.ok && response.status) {
      throw new Error(`404: Does not exist`);
    }

    if (!response.ok) {
      throw json instanceof Object ? json : new Error(json);
    }

    return json as T;
  }
}
