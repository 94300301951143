import Config from '../helpers/config';
import Http from '../helpers/Http';
import { FullSharingSession, NewSharingSession, SharingSession } from '../types';

const sharingSessionRoute = `${Config.apiUrl}/sharing-sessions`;

export async function getSharingSession(id: string): Promise<FullSharingSession> {
  return await Http.get(`${sharingSessionRoute}/${id}`);
}

export async function getSharingSessions(): Promise<SharingSession[]> {
  return await Http.get(sharingSessionRoute);
}

export async function createSharingSession(newSharingSession: NewSharingSession): Promise<any> {
  return await Http.post(sharingSessionRoute, newSharingSession);
}
