import { FullSharingSession } from '../../types';

function getSharingSession(sessionId: string): FullSharingSession {
  return {
    id: sessionId,
    name: `${sessionId} Sharing Session`,
    description: `The #${sessionId} session to share.`,
    adminPin: '123456',
    scenarios: [
      {
        name: 'The First Scenario',
        description: 'Welcome to the first sharing session scenario. This is a wall of text!',
        startingCode: `
function main(): void {
  const hello = "hello";
  const space = " ";
  const world = "world";

  console.log(hello + space + world);
}

main();
      `,
      },
      {
        name: 'The First Scenario',
        description: 'Welcome to the first sharing session scenario. This is a wall of text!',
        startingCode: `
function main(): void {
  const hello = "Goodbye";
  const space = " ";
  const world = "world";

  console.log(hello + space + world);
}

main();
      `,
      },
      {
        name: 'The First Scenario',
        description: 'Welcome to the first sharing session scenario. This is a wall of text!',
        startingCode: `
function main(): void {
  const hello = "See ya";
  const space = " ";
  const world = "world";

  console.log(hello + space + world);
}

main();
      `,
      },
    ],
  };
}

function* sharingSessionGenerator(): Generator<FullSharingSession> {
  let sessionNumber = 1;

  while (true) {
    yield getSharingSession(`${sessionNumber}`);
    sessionNumber++;
  }
}

function countTo(number: number) {
  return [...Array(number).keys()];
}

function createSessions(numberOfSessions: number): FullSharingSession[] {
  const sessionsGenerator = sharingSessionGenerator();
  return countTo(numberOfSessions).map((_) => sessionsGenerator.next().value);
}

export const sharingSessions = createSessions(5);
