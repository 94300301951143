import { FullSharingSession, NewSharingSession, SharingSession } from '../types';

function* idGenerator(startingIndex: number): Generator<string> {
  let id = startingIndex;

  while (true) {
    yield `${id++}`;
  }
}

export default class MockDB {
  private sharingSessions: FullSharingSession[] = [];
  private idMaker: Generator<string>;

  constructor(initialSharingSessions: FullSharingSession[]) {
    this.sharingSessions.push(...initialSharingSessions);
    this.idMaker = idGenerator(this.getMaxId() + 1);
  }

  getSharingSessions(): SharingSession[] {
    return this.sharingSessions.map(({ id, name, description, adminPin }) => ({ id, name, description, adminPin }));
  }

  getSharingSession(id: string): FullSharingSession | null {
    const sharingSession = this.sharingSessions.find((session) => session.id === id);
    return sharingSession ?? null;
  }

  createSharingSession(newSession: NewSharingSession): FullSharingSession {
    const id = this.idMaker.next().value;
    const sharingSession = { id, scenarios: [], ...newSession };

    this.sharingSessions.push(sharingSession);

    return sharingSession;
  }

  private getMaxId(): number {
    if (this.sharingSessions.length === 0) {
      return 1;
    }

    return this.sharingSessions.map((session) => Number(session.id)).sort((a, b) => b - a)[0];
  }
}
