import React from 'react';
import Editor from '@monaco-editor/react';

import Button from '@paprika/button';

import './CodeEditor.scss';
import * as ts from 'typescript';
import { Notification } from '../../types';
import { exec } from 'child_process';

interface CodeEditorProps {
  startingCode: string;
  notify: (notification: Notification) => void;
}

interface Editor {
  getValue(): string;
}

export default function CodeEditor({ startingCode, notify }: CodeEditorProps): JSX.Element {
  const editorRef = React.useRef<Editor>(null);
  const [isEditorAvailable, setIsEditorAvailable] = React.useState(false);
  const [output, setOutput] = React.useState('');

  function handleEditorDidMount(editor: any) {
    (editorRef as any).current = editor;
    setIsEditorAvailable(true);
  }

  const handleOutput = (type: 'log' | 'info' | 'error') => (output: any) => {
    if (type === 'log') {
      setOutput((previousOutput) => previousOutput + `[LOG] ${output}\n`);
    } else if (type === 'info') {
      setOutput((previousOutput) => previousOutput + `[INFO] ${output}\n`);
    } else if (type === 'error') {
      setOutput((previousOutput) => previousOutput + `[ERROR] ${output}\n`);
    }
  };

  function getUserConsole() {
    return {
      log: handleOutput('log'),
      info: handleOutput('info'),
    };
  }

  function shouldSkipTranspilation(code: string): boolean {
    return code.startsWith('// JS\n');
  }

  function handleRun() {
    setOutput('Running...\n');

    try {
      const editor = editorRef.current as Editor;
      const code = editor.getValue();
      const transpiled = !shouldSkipTranspilation(code) ? ts.transpile(code, { lib: ['es2016'] }) : code;
      Function(transpiled)();
      setOutput((previousOutput) => previousOutput + 'Done! \n');
    } catch (error) {
      notify({
        title: 'An error occurred when running your code',
        message: error.message,
        type: 'error',
      });
    }
  }

  React.useEffect(() => {
    // Run the code on CMD-S
    document.addEventListener(
      'keydown',
      (event) => {
        const isCmdKey = window.navigator.platform.match('Mac') ? event.metaKey : event.ctrlKey;
        const isSKey = event.code === 'KeyS';

        if (isCmdKey && isSKey) {
          event.preventDefault();
          handleRun();
        }
      },
      false,
    );
  }, []);

  React.useEffect(() => {
    const originalConsole = console;
    console = { ...console, ...getUserConsole() };

    return () => {
      console = originalConsole;
    };
  }, []);

  return (
    <div>
      <Editor height="30vh" language="typescript" defaultValue={startingCode} onMount={handleEditorDidMount} />
      {isEditorAvailable && (
        <>
          <Button kind="primary" className="code-editor-run" onClick={handleRun}>
            Run
          </Button>
          <Button kind="minor" onClick={() => setOutput('')}>
            Clear Output
          </Button>
        </>
      )}
      <div>
        <p>Output</p>
        <pre className="code-editor-output">{output}</pre>
      </div>
    </div>
  );
}
