import React from 'react';
import NotificationCard from '@paprika/notification-card';

interface NotificationBubblyProps {
  headerText: string;
  bodyText: string;
}

export default function NotificationBubbly({ headerText, bodyText }: NotificationBubblyProps): JSX.Element {
  return (
    <NotificationCard>
      <NotificationCard.Header level={1}>{headerText}</NotificationCard.Header>
      <NotificationCard.Body>{bodyText}</NotificationCard.Body>
    </NotificationCard>
  );
}
