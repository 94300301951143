import React from 'react';
import Toast from '@paprika/toast';
import { Notification } from '../../types';

import './NotificationToast.scss';
import { Link } from 'react-router-dom';

interface NotificationToastProps extends Notification {
  onClose: () => void;
}

const AUTO_CLOSE_DELAY = 5000;

// Notitifcation will auto-close, but opening needs to be contolled by the parent component.
export default function NotificationToast({
  title,
  message,
  type,
  link,
  onClose,
}: NotificationToastProps): JSX.Element {
  return (
    <div className="notification-toast">
      <Toast canAutoClose autoCloseDelay={AUTO_CLOSE_DELAY} hasCloseButton onClose={onClose} kind={type}>
        <h3>{title}</h3>
        <p>{message}</p>
        {!!link && <Link to={link.url}>{link.message}</Link>}
      </Toast>
    </div>
  );
}
