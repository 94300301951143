import React from 'react';
import Spinner from '@paprika/spinner';
import { NewSharingSession, Notification } from '../../types';
import { getErrorMessage } from '../../helpers/errors';
import { useSharingSessions } from '../../hooks/useSharingSessions';
import TitleHeader from '../../components/TitleHeader/TitleHeader';
import SessionList from '../../components/SessionList/SessionList';
import NotificationBubbly from '../../components/NotificationBubbly/NotificationBubbly';

import './ListSessionPage.scss';

interface ListSessionPageProps {
  notify: (notification: Notification) => void;
}

function ListSessionPage({ notify }: ListSessionPageProps): JSX.Element {
  const {
    sharingSessions,
    isLoading,
    isCreatingSession,
    hasErrors,
    getError,
    createError,
    createNewSharingSession,
  } = useSharingSessions();

  const handleSave = React.useCallback(
    async (session: NewSharingSession): Promise<void> => {
      try {
        const newSession = await createNewSharingSession(session);
        notify({
          title: 'Sharing Session Created',
          message: `The "${newSession.name}" session has been created`,
          type: 'success',
          link: {
            url: `/sessions/${newSession.id}`,
            message: 'Click here to visit the new session',
          },
        });
      } catch (error) {
        notify({
          title: 'Failed to Create the Session',
          message: `A error occurred while creating the session: ${getErrorMessage(createError)}`,
          type: 'error',
        });
      }
    },
    [createNewSharingSession, notify],
  );

  function renderSessionList() {
    if (isLoading) {
      return <Spinner className="session-spinner" />;
    }

    if (hasErrors) {
      const error = createError || getError;

      return (
        <NotificationBubbly
          headerText={'An Error Occurred'}
          bodyText={`The following error occurred: ${getErrorMessage(error)}`}
        />
      );
    }

    if (sharingSessions.length === 0) {
      return (
        <NotificationBubbly
          headerText={'No Sharing Sessions Found'}
          bodyText={'Create a sharing session to get started!'}
        />
      );
    }

    return <SessionList sharingSessions={sharingSessions} />;
  }

  return (
    <div className="list-session-page">
      <TitleHeader isSaving={isCreatingSession} createSharingSession={handleSave} />
      {renderSessionList()}
    </div>
  );
}

export default ListSessionPage;
