import { EnvironmentConfigurationError } from './errors';

/**
 * The environment expected by the program.
 * ASSUMPTION: All keys in the environment are required.
 */
interface Environment {
  apiUrl: string;
  useMocks: boolean;
}

/**
 * Sets up the environment and returns a getter for the current environment.
 */
function setupEnvironment(): () => Environment {
  const environment: Environment = {
    apiUrl: process.env.REACT_APP_API_URL as string,
    useMocks: process.env.REACT_APP_USE_MOCKS === 'true',
  };

  Object.entries(environment).forEach(([key, value]) => {
    if (value === undefined) {
      throw new EnvironmentConfigurationError(key);
    }
  });

  return () => environment;
}

const getEnvironemnt = setupEnvironment();

export default getEnvironemnt();
