type JsonObject = Record<string, unknown>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getErrorMessage(error: any): string {
  if (error instanceof Error) {
    return error.message;
  }

  if (error instanceof Object) {
    return JSON.stringify(error);
  }

  return error;
}

export class EnvironmentConfigurationError extends Error {
  constructor(key: string) {
    super(`Expected ${key} to be given as an environment variable, but was undefined.`);
  }
}

export class HttpRequestFailedError extends Error {
  constructor(error: JsonObject) {
    super(`Error: ${error}`);
  }
}
