import Heading from '@paprika/heading';
import React from 'react';
import Card from '../../components/Card/Card';
import { Notification, UrlParams } from '../../types';
import { useParams } from 'react-router-dom';
import { useFetch } from '../../hooks/useFetch';
import { getSharingSession } from '../../api/sharingSessions';
import Spinner from '@paprika/spinner';
import Breadcrumbs from '@paprika/breadcrumbs';
import { ScenarioList } from '../../components/ScenarioList/ScenarioList';

import './SharingSessionPage.scss';
interface SharingSessionPageProps {
  notify: (notification: Notification) => void;
}

function Page({ children }: { children?: JSX.Element | JSX.Element }) {
  return (
    <Breadcrumbs>
      <div className="sharing-session-navigation">
        <Breadcrumbs.Link href="/sessions">{'<'} Sharing Sessions</Breadcrumbs.Link>
      </div>
      {children}
    </Breadcrumbs>
  );
}

export default function SharingSessionPage({ notify }: SharingSessionPageProps): JSX.Element | null {
  const { id } = useParams<UrlParams>();
  const { isLoading, data: sharingSession, error } = useFetch(getSharingSession, {
    initialArguments: [id],
    loadOnMount: true,
  });

  React.useEffect(() => {
    if (error) {
      notify({
        title: 'An error occurred',
        message: `The following error occurred while retrieving the sharing session: ${error}`,
        type: 'error',
      });
    }
  }, [error]);

  if (isLoading) {
    return (
      <Page>
        <Spinner />
      </Page>
    );
  }

  if (error) {
    return <Page />;
  }

  return (
    <Page>
      <>
        <Card>
          <Heading level={1}>{sharingSession.name}</Heading>
        </Card>
        <Card>{sharingSession.description}</Card>
        <Card>
          <ScenarioList scenarios={sharingSession.scenarios} notify={notify} />
        </Card>
      </>
    </Page>
  );
}
