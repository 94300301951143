import Button from '@paprika/button';
import Modal from '@paprika/modal';
import React from 'react';
import { SharingSession } from '../../types';

interface AdminLoginModalProps {
  session: SharingSession;
  onClose: () => void;
}

// Essentially a type declaration for the Header child components.
const Header: (props: any) => JSX.Element = (Modal as any).Header;
const Content: (props: any) => JSX.Element = (Modal as any).Content;
const Footer: (props: any) => JSX.Element = (Modal as any).Footer;

export default function AdminLoginModal({ session, onClose }: AdminLoginModalProps): JSX.Element {
  return (
    <Modal isOpen onClose={onClose}>
      <Header>Coming Soon...</Header>
      <Content>
        <p>Logging on as an admin will allow you to create and edit sharing sessions.</p>
        <p>Until this is implemented, reach out to @gregory_gzik on slack to help create your public session.</p>
      </Content>
      <Footer>
        <Button onClick={onClose}>Close</Button>
      </Footer>
    </Modal>
  );
}
