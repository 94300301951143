import React from 'react';
import Avatar from '@paprika/avatar';
import Button from '@paprika/button';
import PaprikaCard from '@paprika/card';
import './SessionList.scss';
import { SharingSession } from '../../types';
import { useHistory } from 'react-router-dom';
import AdminLoginModal from '../AdminLoginModal/AdminLoginModal';

interface ReactProps {
  className?: string;
  children: any;
}

interface SessionListProps {
  sharingSessions: SharingSession[];
}

type CardType = typeof PaprikaCard & {
  Header: (props: ReactProps) => JSX.Element;
  Content: (props: ReactProps) => JSX.Element;
  Title: (props: ReactProps) => JSX.Element;
  Text: (props: ReactProps) => JSX.Element;
  Footer: (props: ReactProps) => JSX.Element;
};

interface AdminLoginModal {
  showModal: boolean;
  session?: SharingSession;
}

const Card = PaprikaCard as CardType;

export default function SessionList({ sharingSessions }: SessionListProps): JSX.Element {
  const [adminModalState, setAdminModalState] = React.useState<AdminLoginModal>({ showModal: false });
  const history = useHistory();

  const showAdminModal = adminModalState.showModal;

  function handleEnterAsGuest(sessionId: string) {
    history.push(`/sessions/${sessionId}`);
  }

  function handleEnterAsAdmin(session: SharingSession) {
    setAdminModalState({ showModal: true, session });
  }

  function resetAdminModal() {
    setAdminModalState({ showModal: false });
  }

  return (
    <div className="session-list-area">
      {showAdminModal && (
        <AdminLoginModal session={adminModalState.session as SharingSession} onClose={resetAdminModal} />
      )}
      {sharingSessions.map((session) => (
        <Card key={session.id} size="medium" className="session-list-card">
          <Card.Header>
            <Avatar>{session.name[0]}</Avatar>
          </Card.Header>
          <Card.Content>
            <Card.Title>{session.name}</Card.Title>
            <Card.Text>{session.description}</Card.Text>
            <Button className="session-list-card-button" kind="primary" onClick={() => handleEnterAsGuest(session.id)}>
              Enter as Guest
            </Button>
            <Button className="session-list-card-button" kind="secondary" onClick={() => handleEnterAsAdmin(session)}>
              Enter as Admin
            </Button>
          </Card.Content>
        </Card>
      ))}
    </div>
  );
}
