import { rest } from 'msw';
import Config from '../helpers/config';
import { NewSharingSession } from '../types';
import MockDB from './MockDB';
import { sharingSessions } from './models/sharingSessions';

const routes = {
  sharingSession: `${Config.apiUrl}/sharing-sessions`,
};

const database = new MockDB(sharingSessions);

const DELAY = 1500;

export const handlers = [
  rest.get(`${routes.sharingSession}/:id`, (request, response, context) => {
    const { id } = request.params;
    const sharingSession = database.getSharingSession(id);
    return sharingSession !== null
      ? response(context.delay(DELAY), context.status(200), context.body(JSON.stringify(sharingSession)))
      : response(context.delay(DELAY), context.status(404));
  }),
  rest.get(routes.sharingSession, (_, response, context) => {
    const sharingSessions = database.getSharingSessions();
    return response(context.delay(DELAY), context.status(200), context.body(JSON.stringify(sharingSessions)));
  }),
  rest.post(routes.sharingSession, (request, response, context) => {
    const newSharingSession: NewSharingSession = JSON.parse(request.body as string);
    const result = database.createSharingSession(newSharingSession);
    return response(context.delay(DELAY), context.status(201), context.body(JSON.stringify(result)));
  }),
];
