import React from 'react';
import Heading from '@paprika/heading';
import Button from '@paprika/button';
import Card from '../Card/Card';
import CreateSessionModal from '../SessionModal/CreateSessionModal';
import './TitleHeader.scss';
import { NewSharingSession } from '../../types';

interface HeaderProps {
  isSaving: boolean;
  createSharingSession: (session: NewSharingSession) => Promise<void>;
}

export default function TitleHeader({ isSaving, createSharingSession }: HeaderProps): JSX.Element {
  const [isCreatingSession, setIsCreatingSession] = React.useState(false);

  return (
    <div>
      <Card>
        <Heading level={1}>Galvanize Sharing Sessions</Heading>
      </Card>
      <div className="title-header-button">
        <Button onClick={() => setIsCreatingSession(true)} kind="primary">
          + New Sharing Session
        </Button>
      </div>
      {isCreatingSession && (
        <CreateSessionModal
          isOpen={isCreatingSession}
          onClose={() => setIsCreatingSession(false)}
          isSaving={isSaving}
          createSharingSession={createSharingSession}
        />
      )}
    </div>
  );
}
