import React from 'react';

interface FetchResult<T> {
  isLoading: boolean;
  data: T;
  error: Error | null;
  load: (...args: any) => Promise<T>;
}

interface UseFetchOptions<T> {
  initialData: T | null;
  loadOnMount: boolean;
  initialArguments: any[];
}
export function useFetch<T>(
  fetcher: (...args: any) => Promise<T>,
  { initialData = null, loadOnMount = false, initialArguments = [] }: Partial<UseFetchOptions<T>> = {},
): FetchResult<T> {
  const [isLoading, setIsLoading] = React.useState(loadOnMount);
  const [data, setData] = React.useState(initialData);
  const [error, setError] = React.useState(null);

  const load = React.useCallback(async (...args: any) => {
    setIsLoading(true);

    try {
      const result = await fetcher(...args);
      setData(result);
      return result;
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    if (loadOnMount) {
      load(...initialArguments);
    }
  }, []);

  return {
    isLoading,
    error,
    data: data as T,
    load: load as (...args: any) => Promise<T>,
  };
}
